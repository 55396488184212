@font-face {
  font-family: 'bmw-icons';
  src:  url($font-path + 'icons/bmw-icons.eot');
  src:  url($font-path + 'icons/bmw-icons.eot#iefix') format('embedded-opentype'),
  url($font-path + 'icons/bmw-icons.ttf') format('truetype'),
  url($font-path + 'icons/bmw-icons.woff') format('woff'),
  url($font-path + 'icons/bmw-icons.svg#bmw-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i.bmw-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bmw-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bmwi-car-compare:before {
  content: "\e90e";
}
.bmwi-360-camera:before {
  content: "\e90f";
}
.bmwi-side-view:before {
  content: "\e90c";
}
.bmwi-camera:before {
  content: "\e90d";
}
.bmwi-menu:before {
  content: "\e90b";
}
.bmwi-close:before {
  content: "\e900";
}
.bmwi-car-front-mini:before {
  content: "\e908";
}
.bmwi-car-front:before {
  content: "\e909";
}
.bmwi-360-view:before {
  content: "\e90a";
}
.bmwi-fuel-type-petrol:before {
  content: "\e901";
}
.bmwi-fuel-type-mhev:before {
  content: "\e902";
}
.bmwi-fuel-type-h2:before {
  content: "\e903";
}
.bmwi-fuel-type-gase:before {
  content: "\e904";
}
.bmwi-fuel-type-diesel:before {
  content: "\e905";
}
.bmwi-fuel-type-bev:before {
  content: "\e906";
}
.bmwi-fuel-type-phev:before {
  content: "\e907";
}
