$font-path: '/assets/fonts/';

@font-face {
  font-family: 'bmw_next';
  src: url($font-path + 'bmw-next/BMWTypeNext-Bold.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'bmw_next';
  src: url($font-path + 'bmw-next/BMWTypeNext-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'bmw_next';
  src: url($font-path + 'bmw-next/BMWTypeNext-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
