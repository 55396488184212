.text-uppercase {
  text-transform: uppercase;
}

@each $weight in [100, 200, 300, 400, 500, 600, 700, 800, 900] {
  .fw-#{$weight} {
    font-weight: $weight;
  }
}

$spaces: (
  "0": 0,
  "1": 5,
  "2": 10,
  "3": 20,
  "4": 30,
  "5": 40,
  "6": 50,
);

@each $key,$value in $spaces {
  // Padding
  .p-#{$key} {
    padding: #{$value}px !important;
  }

  .py-#{$key} {
    padding-top: #{$value}px !important;
    padding-bottom: #{$value}px !important;
  }

  .px-#{$key} {
    padding-left: #{$value}px !important;
    padding-right: #{$value}px !important;
  }

  .pt-#{$key} {
    padding-top: #{$value}px !important;
  }

  .pb-#{$key} {
    padding-bottom: #{$value}px !important;
  }

  .ps-#{$key} {
    padding-left: #{$value}px !important;
  }

  .pe-#{$key} {
    padding-right: #{$value}px !important;
  }

  // Margin
  .m-#{$key} {
    margin: #{$value}px !important;
  }

  .my-#{$key} {
    margin-top: #{$value}px !important;
    margin-bottom: #{$value}px !important;
  }

  .mx-#{$key} {
    margin-left: #{$value}px !important;
    margin-right: #{$value}px !important;
  }

  .mt-#{$key} {
    margin-top: #{$value}px !important;
  }

  .mb-#{$key} {
    margin-bottom: #{$value}px !important;
  }

  .ms-#{$key} {
    margin-left: #{$value}px !important;
  }

  .me-#{$key} {
    margin-right: #{$value}px !important;
  }
}

.position-relative {
  position: relative;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d-none {
  display: none !important;
}

.text-center {
  text-align: center;
}
