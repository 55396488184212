@import 'common/fonts';
@import 'common/icons';
@import 'common/utils';

body.landscape, table.landscape {
  line-height: 1;
  font-size: 38px;
}

body.skyscraper, table.skyscraper {
  line-height: 1;
  font-size: 30px;
}

body.match-your-style, table.match-your-style {
  line-height: 1;
  font-size: 30px;
}

html {
  line-height: 1;
  background-size: cover;
  color: #fff;
  font-family: "bmw_next", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

html:has(body):has(app-home):has(body):has(.landscape) {
  font-size: 38px;
  background: #1c69d4 url('/assets/images/background_landscape.jpg') no-repeat 0 0;
}

html:has(body):has(app-home):has(body):has(.skyscraper) {
  font-size: 30px;
  background: #1c69d4 url('/assets/images/background_skyscraper.png') no-repeat 0 0;
}

html:has(body):has(app-home):has(body):has(.match-your-style) {
  font-size: 30px;
  background: #1c69d4 url('/assets/images/background_skyscraper.png') no-repeat 0 0;
}

body {
  margin: 0;
  &.landscape {
    padding: 60px 80px;
    width: 1920px - (80px * 2);
    height: 1080px - (60px * 2);

    table tr th p, table tr td p {
      max-width: 1110px;
    }
  }

  &.skyscraper {
    padding: 60px 40px;
    width: 1080px - (40px * 2);
    height: 1920px - (60px * 2);

    table tr th p, table tr td p {
      max-width: 370px;
    }
  }

  &.match-your-style {
    padding: 60px 80px;
    width: 1920px - (80px * 2);
    height: 1600px - (60px * 2);

    table tr th p, table tr td p {
      max-width: 1110px;
    }
  }
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
}

h1 {
  font-size: 70px
}

h2 {
  font-size: 45px;
  margin-bottom: 50px;
}

$table-y-space: 25px;
$table-x-space: 25px;


table {
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;

  tr {
    th,td {
      text-align: start;
      padding: $table-y-space $table-x-space;
      border-bottom: 2px solid #ffffff44;
    }

    th {
      font-weight: 400;
    }

    td {
      line-height: 1.25;
    }

    th.time.landscape, td.time.landscape, th.time.match-your-style {
      width: 180px;
    }

    th.time.skyscraper, td.time.skyscraper {
      width: 150px;
    }

    th.language, td.language {
      width: 150px;
    }

    th.slots.landscape, td.slots.landscape, th.slots.match-your-style {
      width: 210px;
    }

    th.slots.skyscraper, td.slots.skyscraper {
      width: 170px;
    }

    th.article, td.article {
      p {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.qr-code-info {
  position: absolute;
  right: 100px;
  bottom: 0;
  display: flex;
  p {
    margin: 0;
    line-height: 1.3;
  }
  img {
    width: 180px;
    height: 180px;
    padding-left: 50px;
  }
}

.clock {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 50px;
}
